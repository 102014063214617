<template>
<div>
  <b-container class="shlajsna">
  <b-row>
    <b-col sm="6" class="align-self-center py-5">
      <h1 class="lg">{{ $t('general.big-title') }}</h1>
      <p class="lg">{{ $t('general.big-title-desc')}}</p>
      <p>
        <b-button variant="primary" :to="'/' + $root.lang.shortName + '/media'" class="mt-4 mr-4" size="lg">{{ $t('general.find-out-more') }}</b-button>
      </p>
    </b-col>
    <b-col sm="6" class="align-self-center  py-5">
      <div class="euBackground" style="position: relative; text-align: center">
        <b-img fluid :src="require('@/assets/img/europe.png')" id="europe" />
        <b-img fluid id="puuulse" :src="require('@/assets/img/circle.png')" />
        <div class="pulse-ring"></div>
      </div>
    </b-col>

  </b-row>
  </b-container>

  <b-container class="shlajsna py-5">
    <b-row class="my-4">
      <b-col order="1" order-sm="0" sm="12" md="6" class="align-self-center mt-3">
      <div class="introVideoOuter" :style="'background-image: url(' + require('@/assets/img/play.png') +');'">
        <div class="introVideo">
          <div class="embed-responsive embed-responsive-16by9">
            <iframe v-if="$i18n.locale == 'sr-Cyrl' || $i18n.locale == 'sr-Latn'" class="embed-responsive-item" src="https://www.youtube.com/embed/A6lYvJmyKnQ?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe v-if="$i18n.locale == 'en-GB'" class="embed-responsive-item" src="https://www.youtube.com/embed/o1zu13SndUE?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      </b-col>
      <b-col order="0" order-sm="1" sm="12" md="5" offset-md="1" class="align-self-center">
        <h1>{{ $t('general.explore')}}</h1>
        <p class="lg">{{ $t('general.explore-description') }}</p>
      </b-col>
    </b-row>
  </b-container>

<div class="mediaLib">
  <b-container class="shlajsna">
  <b-row>
    <b-col cols="12">
      <h1 class="text-center">{{ $t('videos.download-from-video-library') }}</h1>
    </b-col>
    <b-col cols="12">
      <p class="mb-5 mt-3 text-center short lg">
      {{ $t('videos.download-from-video-library-desc') }}
      </p>
    </b-col>
    <b-col cols="12">
      <b-overlay :show="showOverlayVideo">
        <MediaGrid :media="video" />
        <b-row>
          <b-col>
            <p class="text-center">
              <b-button variant="primary" :to="{ name: 'AllVideosPublic', params: {lang : $root.lang.shortName} }" class="my-4" size="lg">
              {{ $t('videos.see-all-videos') }}
              </b-button>
            </p>
          </b-col>
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
  </b-container>
</div>

<div class="mediaLib" v-if="audio.length > 0">
  <b-container class="shlajsna">
  <b-row>
    <b-col cols="12">
      <h1 class="text-center">{{ $t('audio.discover-our-audio-library') }}</h1>
    </b-col>
    <b-col cols="12">
      <p class="mb-5 mt-3 text-center short lg">
      {{ $t('audio.download-from-audio-library-desc') }}
      </p>
    </b-col>
    <b-col cols="12">
      <b-overlay :show="showOverlayAudio">
        <MediaAudioGrid :media="audio" />
        <b-row>
          <b-col>
            <p class="text-center">
              <b-button variant="primary" :to="{ name: 'AllAudioPublic', params: {lang : $root.lang.shortName} }" class="my-4" size="lg">
              {{ $t('audio.discover-our-audio-library') }}
              </b-button>
            </p>
          </b-col>
        </b-row>
      </b-overlay>
    </b-col>
  </b-row>
  </b-container>
</div>

<div class="mediaLib">
  <b-container class="shlajsna">
    <b-row>
      <b-col cols="12">
        <h1 class="text-center">{{ $t('images.discover-our-photo-library') }}</h1>
      </b-col>
      <b-col cols="12">
        <p class="mt-3 text-center short lg">
        {{ $t('images.discover-our-photo-library-desc') }}
        </p>
      </b-col>
    </b-row>
  </b-container>
</div>
<div class="pb-4 shlajsna container">
  <MediaCarousel  />
</div>
<b-container class="shlajsna">
<b-row>
  <b-col>
    <p class="text-center">
      <b-button variant="primary" :to="{ name: 'AllImagesPublic', params: {lang : $root.lang.shortName} }" class="my-4" size="lg">
      {{ $t('images.see-all-images') }}
      </b-button>
    </p>
  </b-col>
</b-row>
</b-container>

<b-container class="shlajsna">
  <Testimonials />
</b-container>

  <b-container class="shlajsna" style="padding: 80px 0;">
    <b-row>
      <b-col cols="12" class="text-center">
        <!-- <div class="logo d-inline-block"></div> -->
            <video 
              loop 
              muted 
              inline 
              width="102px" height="102px" 
              contextmenu="return false" 
              :poster="require('@/assets/dmn-logo.svg')" id="logoVideo"
              @mouseover="playVideo" @focus="playVideo" @mouseout="stopVideo" @blur="stopVideo">
                <source :src="require('@/assets/dmn-logo-anim.mp4')" type="video/mp4">
            </video>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import MediaGrid from '@/components/util/MediaGrid.vue'
import MediaAudioGrid from '@/components/util/MediaAudioGrid.vue'
import MediaCarousel from '@/components/util/MediaCarousel.vue'
import Testimonials from '@/views/Testimonials.vue'

export default {
  name: 'Home',
  components: { MediaGrid, BImg, MediaCarousel, Testimonials, MediaAudioGrid},
  data: function() {
    return {
      video: [],
      audio: [],
      showOverlayVideo: true,
      showOverlayAudio: true
    }
  },
  mounted: function() {
    //window.$(".logo").ripples({ resolution: 50, perturbance: 0.030});
  },

  created: async function() {
    //console.log("Krerana komponenta HOME: jezik je: " + this.$root.lang.IANA);  
    await this.$http.get('/api/media/all/' + this.lang.IANA + '/video/?page=0').then(r => {
      this.video = r.data.content;
    }).catch(e => {
      this.handleError(e);
    });
    this.showOverlayVideo = false;
    await this.$http.get('/api/media/all/' + this.lang.IANA + '/audio/?page=0').then(r => {
      this.audio = r.data.content;
    }).catch(e => {
      this.handleError(e);
    });
    this.showOverlayAudio = false;
  },

  computed: {
    lang: function() {
      return this.$root.lang
    }
  },

  watch: {
     lang : async function(newLang, oldLang) {
      this.showOverlayVideo = true;
      await this.$http.get('/api/media/all/' + newLang.IANA + '/video/?page=0').then(r => {
        this.video = r.data.content;
      }).catch(e => { this.handleError(e); });
      this.showOverlayVideo = false;

      this.showOverlayAudio = true;
      await this.$http.get('/api/media/all/' + newLang.IANA + '/audio/?page=0').then(r => {
        this.audio = r.data.content;
      }).catch(e => { this.handleError(e); });
      this.showOverlayAudio = false;
    }
  },

  metaInfo: function() {
    return {
      title: this.$t('general.full-name')
    }
  },

  methods: {
    playVideo(event) {
      event.target.play()
    },

    stopVideo(event) {
      event.target.pause()
      event.target.load();
    }
  }
}
</script>

<style></style>
