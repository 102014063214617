<template>
  <div>
    <b-row>
      <b-col cols="12">
          <h1 class="my-3 text-center short">{{ $t('general.testimonials') }}</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <hooper :settings="hooperSettings">
          <slide v-for="(tes, i) in testimonialData" :key="i"  :index="i">
            <div class="tesOuter">
              <p class="tes-text">{{ tes.text }}</p>
              <p class="text-center"> 
                <b-avatar size="72px" :src="tes.img" />
              </p>
              <h3>{{ tes.name }}</h3>
              <p class="tesFooter">
                {{ tes.company }}
              </p>
            </div>
          </slide>
          <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BAvatar} from 'bootstrap-vue'
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';

export default {
  name: "Testimonials",
  components: { BAvatar, Hooper,  Slide,  HooperPagination},
  
  data() {
    return {
      testimonialData: {},
      hooperSettings: {
        itemsToShow: 3,
        centerMode: false,
        wheelControl: false,
        autoPlay: false,
        playSpeed: 4000,
        transition: 1200,
        breakpoints: {
          200: { itemsToShow: 1 },
          760: { itemsToShow: 2 },
          1000: { itemsToShow: 3 }
        }
      }
    }
  },
  created() {
    this.testimonialData = this.getTestimonials(this.lang.IANA);
  },

  computed: {
    lang: function() {
      return this.$root.lang
    }
  },

  watch: {
     lang : async function(newLang, oldLang) {
       this.testimonialData = this.getTestimonials(newLang.IANA);
    }
  },
  
  methods: {
    getTestimonials: function(selectedLang) {
      // console.log(this.lang);
      if (selectedLang === "sr-Cyrl") {
        return [
                {
                  text : "\"Медијско умрежавање и јавно доступне информације од значаја су како за струку, тако и за сваког појединца, у сваком делу света. Нинамедиа је са задовољством међу онима који подржавају и учествују у раду стручњака у областима медија  у Дунавском региону.\"",
                  img : require('@/assets/img/testimonials/milena-biga.jpg'),
                  name : "Милена Бига",
                  company: "Нинамедиа"
                },
                {
                  text : "\"Свака иницијатива за повезивање у нашем послу је добра, поготово на начин како је ова постављена. Овакав вид умрежавања ми је посебно драг јер Дунав повезује људе у креативном и културолошком смислу са свим различитостима и сличностима и представља добар темељ на којима могу да се граде здраве и квалитетне везе.\"",
                  img : require('@/assets/img/testimonials/aleksandar-karisik.jpg'),
                  name : "Александар Kаришик",
                  company: "Karišik & Karišik"
                },
                {
                  text : "\"Умрежавање је једна од најбољих основа за успешно пословање и ширење посла. Kао компанија која цени знање, амбицију, вредноћу и посвећеност, радо бисмо подржали ту иницијативу и допринели њеној надахњујућој мисији. ДММ пружа безброј могућности за комуникацију и размену које су драгоцени начини да се прошире хоризонти, а то је оно што ми увек једва чекамо.\"",
                  img : require('@/assets/img/testimonials/aleksandra-svonja.jpg'),
                  name : "Александра Швоња",
                  company: "Classica Nova"
                },
                {
                  text : "\"Већ први резултати Дунавске мреже, посебно у времену пандемије, доказују њену изузетну медијску употребну вредност. Са ширег, друштвеног аспекта, Дунавска мрежа обесмишљава ескалацију етноцентризама који убрзано настају као последице социјалних промена - и практично доказује да до бољитка можемо стићи само уважавањем, повезивањем и међусобном сарадњом.\"",
                  img: require('@/assets/img/testimonials/vladan-jocic.jpg'),
                  name: "Владан Јочић",
                  company: "Mediapont"
                },
                {
                  text : "\"Пројекат попут Дунавске медијске мреже као платформе за размену мултимедијалог садржаја представља изузетан подухват. С друге стране значај ДММ огледа се и у интензивнијој комуникацији и бољем разумевању различитих култура, а с друге стране пружа широк спектар могућности за разне облике сарадње. Путем ДММ шира јавност је у могућности да се ближе упозна са културном и друштвеном сценом Мађарске, али и са српском заједницом у овој земљи.\"",
                  img: require('@/assets/img/testimonials/dijana-djuric.jpg'),
                  name: "Диана Ђурић",
                  company: "Варош ТВ, Будимпешта"
                }
              ];

      } else if (selectedLang === "sr-Latn") {
        
        return [
                {
                  text : "\"Medijsko umrežavanje i javno dostupne informacije od značaja su kako za struku, tako i za svakog pojedinca, u svakom delu sveta. Ninamedia je sa zadovoljstvom među onima koji podržavaju i učestvuju u radu stručnjaka u oblastima medija  u Dunavskom regionu.\"",
                  img : require('@/assets/img/testimonials/milena-biga.jpg'),
                  name : "Milena Biga",
                  company: "Ninamedia"
                },
                {
                  text : "\"Svaka inicijativa za povezivanje u našem poslu je dobra, pogotovo na način kako je ova postavljena. Ovakav vid umrežavanja mi je posebno drag jer Dunav povezuje ljude u kreativnom i kulturološkom smislu sa svim različitostima i sličnostima i predstavlja dobar temelj na kojima mogu da se grade zdrave i kvalitetne veze.\"",
                  img : require('@/assets/img/testimonials/aleksandar-karisik.jpg'),
                  name : "Aleksandar Karišik",
                  company: "Karišik & Karišik"
                },
                {
                  text : "\"Umrežavanje je jedna od najboljih osnova za uspešno poslovanje i širenje posla. Kao kompanija koja ceni znanje, ambiciju, vrednoću i posvećenost, rado bismo podržali tu inicijativu i doprineli njenoj nadahnjujućoj misiji. DMM pruža bezbroj mogućnosti za komunikaciju i razmenu koje su dragoceni načini da se prošire horizonti, a to je ono što mi uvek jedva čekamo.\"",
                  img : require('@/assets/img/testimonials/aleksandra-svonja.jpg'),
                  name : "Aleksandra Švonja",
                  company: "Classica Nova"
                },
                {
                  text : "\"Već prvi rezultati Dunavske mreže, posebno u vremenu pandemije, dokazuju njenu izuzetnu medijsku upotrebnu vrednost . Sa šireg, društvenog aspekta, Dunavska mreža obesmišljava eskalaciju etnocentrizama koji ubrzano nastaju kao posledice socijalnih promena - i praktično dokazuje da do boljitka možemo stići samo uvažavanjem, povezivanjem i međusobnom saradnjom.\"",
                  img: require('@/assets/img/testimonials/vladan-jocic.jpg'),
                  name: "Vladan Jočić",
                  company: "Mediapont"
                },
                {
                  text : "\"Projekat poput Dunavske medijske mreže kao platforme za razmenu multimedijalog sadržaja predstavlja izuzetan poduhvat. S druge strane značaj DMM ogleda se i u intenzivnijoj komunikaciji i boljem razumevanju različitih kultura, a s druge strane pruža širok spektar mogućnosti za razne oblike saradnje. Putem DMM šira javnost je u mogućnosti da se bliže upozna sa kulturnom i društvenom scenom Mađarske, ali i sa srpskom zajednicom u ovoj zemlji.\"",
                  img: require('@/assets/img/testimonials/dijana-djuric.jpg'),
                  name: "Dijana Đurić",
                  company: "Varoš TV, Budimpešta"
                }                
              ];

      } else {

        return [
                {
                  text : "\"Media networking and publicly available information of importance for both experts and each individual in every part of the world. Ninamedia is proud to be among those supporting and taking part in the work done by media experts in the Danube region.\"",
                  img : require('@/assets/img/testimonials/milena-biga.jpg'),
                  name : "Milena Biga",
                  company: "Ninamedia"
                },
                {
                  text : "\"Any initiative for interconnection in our business is good, especially in the way that this one is organised. This type of networking is especially dear to me, because the Danube connects people in a creative and cultural sense, with all their differences and similarities, and represents a good foundation on which healthy and quality relations can be built.\"",
                  img : require('@/assets/img/testimonials/aleksandar-karisik.jpg'),
                  name : "Aleksandar Karišik",
                  company: "Karisik & Karisik"
                },
                {
                  text : "\"Networking is one of the most solid foundations for business success and expansion. As a company that values knowledge, ambition, hard work and commitment, we will gladly support this initiative and contribute to its inspiring mission. DMN offers endless opportunities for communication and exchange which are precious ways to expand horizons and something we always look forward to.\"",
                  img : require('@/assets/img/testimonials/aleksandra-svonja.jpg'),
                  name : "Aleksandra Švonja",
                  company: "Classica Nova"
                }, 
                {
                  text : "\"The first results of the Danube Network, especially during the pandemic, have already proven its exceptional media use value. From a broader, social point of view, the Danube Network takes the escalation of ethnocentrisms that are rapidly emerging as a consequence of social change and renders it senseless - and practically proves that we can improve only by respecting, connecting and cooperating with each other.\"",
                  img: require('@/assets/img/testimonials/vladan-jocic.jpg'),
                  name: "Vladan Jočić",
                  company: "Mediapont"
                },
                {
                  text : "\"The Danube Media Network (DMN) project as a platform for the exchange of multimedia content presents an immense achievement. On the other hand, the DMN reflects a better communication and understanding of different cultures, and, on the other hand, offers a wide diapason of possibilities for the various types of collaboration. Through DMN, the public can get to know better the cultural and social scene of Hungary, but also to have a better insight into the Serbian community in this country.\"",
                  img: require('@/assets/img/testimonials/dijana-djuric.jpg'),
                  name: "Gyurity Diana",
                  company: "Varos TV, Budapest"
                }                
              ];
      }

    }

    
  }

}
</script>

<style></style>
