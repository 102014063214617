<template>
  <div v-if="images">
    <div class="row">
      <div class="col-sm-12">
        <hooper :settings="hooperSettings">
            <slide v-for="i in images" :key="i.id"  :index="i.id">
              <div class="px-3 py-4" style="overflow:hidden;">
                <b-card no-body class="m">
                    <router-link :to="{ name: 'Image', params : { lang: $root.lang.shortName, imageId: i.id } }">
                      <div class="posterPlaceHolder" :style="'background-image: url(\''+ i.posterUrl +'\')'" >
                        <img :src="i.posterUrl" alt="" style="display:none;">
                      </div>
                    </router-link>

                    <b-card-text class="m" text-tag="div">
                      <router-link :to="{ name: 'Image', params : { lang: $root.lang.shortName, imageId: i.id } }">
                      <h2 v-if="$root.lang.shortName=='en'" class="mediaTitle" :title="i.titleEn">{{ i.titleEn | abb(90) | removeLat}}</h2>
                      <h2 v-else  class="mediaTitle" :title="i.title">{{ i.title | abb(90) | removeLat}}</h2>
                      </router-link>
                    </b-card-text>

                    <router-link :to="{ name: 'Image', params : { lang: $root.lang.shortName, imageId: i.id } }">
                    <b-card-footer class="m">{{ $t('images.author') }}: {{ i.author | removeLat }}</b-card-footer>
                    </router-link>
                </b-card>
              </div>
            </slide>
            <hooper-pagination slot="hooper-addons"></hooper-pagination>
        </hooper>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BCardFooter } from 'bootstrap-vue'
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
export default {
  name: "MediaCarousel",
  components: { BCard, BCardFooter, BCardText, Hooper, Slide, HooperPagination },

  data: function() {
    return {
      images: [],
      hooperSettings: {
        itemsToShow: 4,
        centerMode: false,
        wheelControl: false,
        autoPlay: true,
        playSpeed: 4000,
        transition: 1500,
        breakpoints: {
          200: { itemsToShow: 1 },
          760: { itemsToShow: 2 },
          1000: { itemsToShow: 3 }
        }
      }
    }
  },

  computed: {
    lang: function() {
      return this.$root.lang
    }
  },

  created: async function() {
    // console.log("Media Carousel komponenta. Jezik je: " + this.$root.lang.IANA);
    await this.$http.get('/api/media/all/' + this.$root.lang.IANA + '/image/?page=0&size=10').then(r => {
      this.images = r.data.content;
    }).catch(e => { this.handleError(e); })
  },

  watch: {
    lang : async function(newLang, oldLang) {
      await this.$http.get('/api/media/all/' + this.lang.IANA + '/image/?page=0&size=10').then(r => {
        this.images = r.data.content;
      }).catch(e => { this.handleError(e); })
    }
  }

}
</script>

<style></style>
